import styled from '@emotion/styled';

import { colorToAlpha } from 'helpers/insight';
import { InsightType } from 'utils/entities';


export const TimeStoryItem = styled.div`
  position: relative;
  width: 100%;
`;

export const Circle = styled.div `
  width: 32px;
  height: 32px;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => colorToAlpha(props.theme.colors.insights[InsightType.Transportation], 0.12)};
`;

export const TimeScheduleWrapper = styled.div `
  margin-top: 24px;
  display: inline-flex;
  justify-content: center;
  width: 100%;
`;

export const DestinationBlock = styled.div `
  position: relative;
  color: ${props => props.theme.colors.neutrals.white};

  > h2 {
    position: relative;
    z-index: 1;
    text-transform: capitalize;
    color: ${props => props.theme.colors.neutrals.white};
    ${props => props.theme.media.showAt({ at: 1 }) `margin-top: 6px;`}
    ${props => props.theme.media.showAt({ at: 2 }) `margin-top: 12px;`}
  }

  &:before {
    content: '';
    position: absolute;
    opacity: 0.4;
    bottom: 0;
    left: -6px;
    width: calc(100% + 12px);
    height: 34px;
    border-radius: ${props => props.theme.borderRadius.tiny};
    background: ${props => props.theme.colors.neutrals.black};
  }
`;

export const ImageBlock = styled.div <{ imgURL: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${({ imgURL }) => imgURL});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  ${props => props.theme.media.showAt({ to: 2 }) `height: 134px;`}
  ${props => props.theme.media.showAt({ at: 2 }) `height: 227px;`}
`;

export const Content = styled.div `
  background: ${({ theme }) => theme.colors.neutrals.white};
  width: 100%;
  height: 278px;
  text-align: center;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  ${props => props.theme.media.showAt({ at: 2 }) `
    height: 372px;
  `}
`;

interface IStoryTimeScheduleWrapper {
  timeExist: boolean;
}

export const StoryTimeScheduleWrapper = styled.div<IStoryTimeScheduleWrapper> `
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.theme.colors.neutrals.grey3};

  &:not(:first-of-type) {
    ${props => props.theme.isRTL ? 'margin-right' : 'margin-left' }: 32px;
    ${props => props.theme.media.showAt({ at: 2 }) `
      ${props.theme.isRTL ? 'margin-right' : 'margin-left'}: 40px;
    `}
  }

  > div:last-child {
    margin-top: 4px;
  };

  > div:first-of-type {
    border: none;
    background: ${props => props.timeExist ? colorToAlpha(props.theme.colors.insights[InsightType.Transportation], 0.08) : props.theme.colors.neutrals.grey9};

    > svg {
      fill: ${props => props.timeExist ? props.theme.colors.insights.transportation : props.theme.colors.neutrals.grey3};
      > path {
      fill: ${props => props.timeExist ? props.theme.colors.insights.transportation : props.theme.colors.neutrals.grey3};
    }
    }
  }
`;

export const NoDestinationTypeBlock = styled.div`
  position: relative;
  padding: ${props => props.theme.isRTL ? '8px 57px 8px 34px' : '8px 34px 8px 57px' };
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  background: ${props => props.theme.colors.neutrals.white};
  box-shadow: ${props => props.theme.shadow.level3};
  color: ${props => props.theme.colors.insights.transportation};

  > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${props => props.theme.isRTL ? 'right' : 'left'}: 32px;
    > path {
      fill: ${props => props.theme.colors.insights.transportation};
    }
  }
`;

export const GreyStrip = styled.div `
  height: 4px;
  width: 43px;
  border-radius: ${({ theme }) => theme.borderRadius.micro};
  background: ${props => props.theme.colors.neutrals.grey8};
  ${({ theme }) => theme.isRTL ? 'margin-right' : 'margin-left'}: 8px;
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    margin: 16px 0 0 0;
  `}
`;

export const TransportationAccordionWrapper = styled.div`
  position: relative;
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    padding: 0 24px;
    border-radius: ${theme.borderRadius.tiny};
    border: 1px solid ${theme.colors.neutrals.grey8};
    background: ${theme.colors.neutrals.white};
    overflow: hidden;
  `};
  ${({ theme }) => theme.media.showAt({ to: 1 }) `
    padding: 8px 24px;
    background: ${theme.colors.neutrals.white};
    border-radius: ${theme.borderRadius.medium};
    box-shadow: ${theme.shadow.level3};
  `}
`;

export const StoryWrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadow.level3};
`;

export const RouteTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => `
    svg {
      margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(0.5)};
      path {
        fill: ${theme.colors.neutrals.grey3};
      }
    }
  `}
`;

export const AccordionTabsHeader = styled.div`
  ${({ theme }) => theme.media.showAt({ from: 2 }) `
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid ${theme.colors.neutrals.grey8};
    & > div {
      margin-${theme.isRTL ? 'left' : 'right'}: 32px;
      &:last-of-type {
        margin: 0;
      }
    }
  `}
  ${({ theme }) => theme.media.showAt({ to: 1 }) `
    display: inline-flex;
    align-items: center;
    border-radius: ${theme.borderRadius.smallestSideRound};
    background: ${theme.colors.neutrals.grey10};
    border: 1px solid ${theme.colors.neutrals.grey9};
    padding: 2px;
  `}
`;

export const SectionBody = styled.div<{ type: InsightType }>`
  .highlight {
    background: ${({ theme, type }) => colorToAlpha(theme.colors.insights[type], 0.08)};
    border-radius: ${({ theme }) => theme.borderRadius.tiny};
    padding: 0 2px;
  }
  position: relative;
  padding: 16px 0;
  ${({ theme }) => theme.media.showAt({ from: 2 }) `
    padding: ${theme.isRTL ? '16px 24px 16px 0;' : '16px 0 16px 24px;'};
  `}
`;

export const EmptySection = styled.div`
  color: ${({ theme }) => theme.colors.neutrals.grey3};
`;

const MOBILE_WALK_TIME_WIDTH = 82;

export const RouteWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    flex-wrap: wrap;
    > div:nth-of-type(1) {
      min-width: ${MOBILE_WALK_TIME_WIDTH}px;
    }
    > div:nth-of-type(2) {
      max-width: calc(100% - ${MOBILE_WALK_TIME_WIDTH}px);
    }
    > div:nth-of-type(3) {
      padding-${theme.isRTL ? 'right' : 'left'}: ${MOBILE_WALK_TIME_WIDTH}px;
      flex-basis: 100%;
    }
  `}
  ${({ theme }) => theme.media.showAt({ from: 2 }) `
    > div:nth-of-type(1) {
      min-width: 112px;
    }
    > div:nth-of-type(2) {
      min-width: 200px;
    }
  `}
`;

export const LinesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: auto;
  min-height: 24px;
  ${({ theme }) => theme.media.showAt({ from: 2 }) `
    margin-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(3)};
  `}
`;

interface LineWrapperProps {
  color: string;
  shape: 'circle' | 'square';
  small?: boolean;
}

export const LineWrapper = styled.div<LineWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    font-size: 10px !important;
  }
  color: ${({ theme, color }) => color === 'hsl(60, 100%, 45%)'
    ? theme.colors.neutrals.grey1
    : theme.colors.neutrals.white
  };
  background: ${({ color, theme }) => color || theme.colors.neutrals.grey3};
  ${({ shape, theme, small }) => {
    switch (shape) {
      case 'square':
        return `
          margin: 1px;
          height: ${small ? 16 : 18}px;
          border-radius: ${theme.borderRadius.micro};
          padding: 0 4px;
        `;
      case 'circle':
      default:
        return `
          margin: 2px;
          width: ${small ? 16 : 18}px;
          height: ${small ? 16 : 18}px;
          border-radius: ${theme.borderRadius.round};
          & > div {
            font-weight: 700;
          }
        `;
    }
  }}
`;

export const CollapseButton = styled.div<{ type: InsightType }>`
  margin-bottom: 8px;
  color: ${({ theme, type }) => theme.colors.insights[type]};
  display: inline-block;
`;

export const SeeOnMapWrapper = styled.span`
  display: block;
  ${props => props.theme.media.showAt({ at: 2 }) `padding-bottom: 8px;`}
`;
